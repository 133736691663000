import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Input, Table} from "antd";
import { DatePickerProps } from "antd/lib/date-picker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import { activityLog } from "./api";
import { ActivityModel } from "./models";
import moment, { Moment } from "moment";
import Search from "antd/lib/input/Search";
// import { DatePickerProps } from "@mui/x-date-pickers";
const { Column } = Table;

export default function ActivityLogsTable() {
  const dispatch = useDispatch();
  const activitylog = useSelector((state: RootState) => state.activity.all);
  const retailerId = useSelector((state: RootState) => state.activity.id);
  const [replica, setReplica] = React.useState<ActivityModel[]>([]);
  const [filterData, setFilterData] = React.useState({ logtype: { 'add': false, 'edit': false, 'delete': false }, logTypeStr: '', searchText: '' })
  const [pagenumber, setpagenumber] = React.useState(1)
  const [limit, setlimit] = React.useState(25);
  const [date, setDate] = React.useState<{ fromDate: Moment | null, toDate:Moment | null}>({
    fromDate: null,
    toDate: null,
  });
  const handleFromDateChange: DatePickerProps['onChange'] = (dateFrom, dateString) => {
    setDate({ ...date, fromDate: dateFrom });
  };
  const handleToDateChange: DatePickerProps['onChange'] = (dateTo, dateString) => {
    setDate({ ...date, toDate: dateTo });
    console.log("dateto",dateString ) 
  };
  
  // const handleDateChange = (event: any) => {
  //   const { name, value } = event.target;
  //   setDate({ ...date, [name]: value });
  // };
  // const handleDateChange = (date: moment.Moment | null, dateString: string) => {
  //   setDate({ ...date, toDate: dateString, fromDate: dateString });
  // };
  const handleUpdate = () => {
    setFirstPage();
    dispatch(activityLog({
      retailerId: retailerId,
      pagenumber, limit,
      searchText: filterData.searchText,
      fromDate: date.fromDate?.format("MM/DD/YYYY"),
      toDate: date.toDate?.format("MM/DD/YYYY")
    }));
  }

  const orderStorageName = "activity_order";
  let localOrder = localStorage.getItem(orderStorageName);
  const [order, setOrder] = useState<string | null>(localOrder);
  const [jsonOrder, setJsonOrder] = useState(
    JSON.parse(
      order !== null
        ? order
        : JSON.stringify({ order: "ascend", column: "name" })
    )
  );
  const [columnAttr, setcolumnAttr] = React.useState<object>({
    sortOrder: jsonOrder?.order == "ascend" ? "ascend" : "descend",
    sortDirections: ["ascend", "descend", "ascend"],
  });
  useEffect(() => {
    if (order == null) {
      localStorage.setItem(
        orderStorageName,
        JSON.stringify({ order: "ascend", column: "name" })
      );
      setOrder(JSON.stringify({ order: "ascend", column: "name" }));
      setJsonOrder({ order: "ascend", column: "name" });
    }
    if (order !== null) {
      setJsonOrder(JSON.parse(order));
    }
  }, [order]);

  const onHeaderCell = (columnName: string) => {
    return {
      onClick: () => {
        if (jsonOrder.order == "descend") {
          localStorage.setItem(
            orderStorageName,
            JSON.stringify({ order: "ascend", column: columnName })
          );
          setOrder(JSON.stringify({ order: "ascend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "ascend" });
        } else {
          localStorage.setItem(
            orderStorageName,
            JSON.stringify({ order: "descend", column: columnName })
          );
          setOrder(JSON.stringify({ order: "descend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "descend" });
        }
      },
    };
  };

  // change the api endpoint
  const handleChangeSearch = (e: { target: { value: string; }; }) => {
    filterData.searchText = e.target.value;
    setFilterData({ ...filterData })
    let logtypestr = ''
    console.log("e.target.value", e.target.value);
    setTimeout(() => {
      setFirstPage();
      console.log("e.target.value setTimeout", filterData.searchText);
      dispatch(activityLog({
        retailerId: retailerId,
        pagenumber, limit, logType: logtypestr,
        searchText: filterData.searchText
      }));
    }, 1000);
  };

  const handleLogTypeChanges = (e: { target: any; }) => {
    let logTypeArray = { 'add': false, 'edit': false, 'delete': false };
    switch (e.target.name) {
      case 'add':
        logTypeArray['add'] = e.target.checked
        break;
      case 'edit':
        logTypeArray['edit'] = e.target.checked
        break;
      case 'delete':
        logTypeArray['delete'] = e.target.checked
        break;
    }

    let logtypestr = '';
    if (logTypeArray.add) {
      logtypestr += 'Add';
    }
    if (logTypeArray.edit) {
      if (logtypestr != '')
        logtypestr += ',';
      logtypestr += 'Edit';
    }
    if (logTypeArray.delete) {
      if (logtypestr != '')
        logtypestr += ',';
      logtypestr += 'Delete';
    }
    filterData.logTypeStr = logtypestr;
    filterData.logtype = logTypeArray;

    setFilterData(filterData);
  }

  const searchFilter = () => {
    setFirstPage();
    dispatch(activityLog({
      retailerId: retailerId,
      pagenumber, limit, logType: filterData.logTypeStr,
      searchText: filterData.searchText
    }))
  }

  useEffect(() => {
    dispatch(activityLog({
      retailerId: retailerId,
      pagenumber,
      limit,
      searchText: filterData.searchText
    }))
  }, []);

  const PageUp = () => {
    const page = pagenumber + 1;
    document.getElementById('page_number')!.innerHTML = page.toString();
    setpagenumber(page);
    setlimit(limit);
    dispatch(activityLog({
      retailerId: retailerId,
      pagenumber: page,
      logType: filterData.logTypeStr,
      limit: limit,
      searchText: filterData.searchText,
      fromDate: date.fromDate?.format("MM/DD/YYYY"),
      toDate: date.toDate?.format("MM/DD/YYYY"),
    }));
  };

  const PageDown = () => {
    const page = pagenumber - 1;
    document.getElementById('page_number')!.innerHTML = page.toString();
    setpagenumber(page);
    setlimit(limit);
    dispatch(activityLog({
      retailerId: retailerId,
      logType: filterData.logTypeStr,
      pagenumber: page,
      limit: limit,
      searchText: filterData.searchText,
      fromDate: date.fromDate?.format("MM/DD/YYYY"),
      toDate: date.toDate?.format("MM/DD/YYYY")
    }));
  }

  const setFirstPage = () => {
    const page = 1;
    document.getElementById('page_number')!.innerHTML = page.toString();
    setpagenumber(page);
    setlimit(limit);
  }

  useEffect(() => {
    setReplica(activitylog)
  }, [activitylog]);

  return (
    <>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1em'
      }}>
        <Search
          name="searchText"
          placeholder="Search here"
          style={{ width: 300, marginRight: '1em' }}
          allowClear
          onChange={handleChangeSearch}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ marginBottom: 8 }}>From date</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DatePicker
            format={"MM/DD/YYYY"}
            placeholder="MM/DD/YYYY"
            value={date.fromDate ? moment(date.fromDate) : null}
            onChange={handleFromDateChange}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ marginBottom: 8 }}>To</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DatePicker
            format={"MM/DD/YYYY"}
            placeholder="MM/DD/YYYY" 
            value={date.toDate ? moment(date.toDate) : null}
            onChange={handleToDateChange}
       
          />

        </div>
        <p style={{ marginBottom: 8 }}>To date</p>

        <Button type="primary" style={{ marginLeft: 6 }} onClick={handleUpdate}>Update</Button>

        <p style={{ marginRight: '1em', marginLeft: 'auto' }}>
          Total Count: &nbsp; {replica.length}
        </p>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
        marginBottom: '1em', height: '50px', width: '100%', padding: '0 20px', backgroundColor: '#f9f9f9', borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
      }}>
        <Checkbox
          id="add" onChange={handleLogTypeChanges} name="add" value="add" style={{
            marginRight: '10px', height: '20px', width: '20px'
          }}
        />
        <label
          htmlFor="add" style={{
            marginRight: '20px', fontSize: '16px', fontWeight: '500'
          }}
        >
          Add
        </label>
        <Checkbox
          id="edit" onChange={handleLogTypeChanges} name="edit" value="edit" style={{
            marginRight: '10px', height: '20px', width: '20px'
          }}
        />
        <label
          htmlFor="edit"
          style={{
            marginRight: '20px', fontSize: '16px', fontWeight: '500'
          }}
        >
          Edit
        </label>
        <Checkbox
          id="delete"
          onChange={handleLogTypeChanges}
          name="delete"
          value="delete"
          style={{
            marginRight: '10px', height: '20px', width: '20px'
          }}
        />
        <label
          htmlFor="delete"
          style={{
            marginRight: '20px', fontSize: '16px', fontWeight: '500'
          }}        >          Delete
        </label>
        <Button
          onClick={searchFilter}
          style={{
            marginLeft: 'auto', height: '35px', width: '100px', fontSize: '16px', fontWeight: '500', backgroundColor: '#007bff', color: '#fff',
            border: 'none', borderRadius: '5px', cursor: 'pointer'
          }}        >
          Search
        </Button>
      </div>

      <Table
        dataSource={[...replica]
          .reverse()
          .map((el, index) => ({ ...el, index: index + 1, key: el.id }))}
        //columns={columns}
        pagination={false}
      >

      <Column
        title="SL No"
        dataIndex="index"
        key="index"
        {...(jsonOrder.column == "index" && columnAttr)}
        onHeaderCell={(column) => onHeaderCell("index")}
        sorter={(a: any, b: any) => {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return 1;
          }
          return 0;
        }}
      />

      <Column
        title="Activity Name"
        dataIndex="name"
        key="name"
        {...(jsonOrder.column == "name" && columnAttr)}
        onHeaderCell={(column) => onHeaderCell("name")}
        sorter={(a: any, b: any) => {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return 1;
          }
          return 0;
        }}
      />

      <Column
        title="Activity Log Description"
        dataIndex="activityLogDescription"
        key="activityLogDescription"
        {...(jsonOrder.column == "activityLogDescription" && columnAttr)}
        onHeaderCell={(column) => onHeaderCell("activityLogDescription")}
        sorter={(a: any, b: any) => {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return 1;
          }
          return 0;
        }}
      />

      <Column
        title="Activity Date"
        key="operationDate"
        render={(row: any) => {
          return moment(row.operationDate).format("MM/DD/YYYY");
        }}
        sorter={(a: any, b: any) => {
          if (a.operationDate < b.operationDate) {
            return -1;
          }
          if (a.operationDate > b.operationDate) {
            return 1;
          }
          return 0;
        }}
        {...(jsonOrder.column == "operationDate" && columnAttr)}
        onHeaderCell={(column) => onHeaderCell("operationDate")}
      />

      <Column
        title="Activity Type"
        dataIndex="logType"
        key="logType"
        {...(jsonOrder.column == "logType" && columnAttr)}
        onHeaderCell={(column) => onHeaderCell("logType")}
        sorter={(a: any, b: any) => {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return 1;
          }
          return 0;
        }}
      />

      <Column
        title="Activity UserName"
        dataIndex="activityUserName"
        key="activityUserName"
        {...(jsonOrder.column == "activityUserName" && columnAttr)}
        onHeaderCell={(column) => onHeaderCell("activityUserName")}
        sorter={(a: any, b: any) => {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return 1;
          }
          return 0;
        }}
      />
      </Table>

      <ul className="ant-pagination ant-table-pagination">
        <li title="Previous Page" className={`ant-pagination-prev ${pagenumber === 1 ? 'ant-pagination-disabled' : ''}`} onClick={PageDown}>
          <a className="ant-pagination-item-link">
            <span role="img" aria-label="left" className="anticon anticon-left">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
              </svg>
            </span>
          </a>
        </li>
        <li title="1" className="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active">
          <a id="page_number">1</a>
        </li>
        <li title="Next Page" className={`ant-pagination-next ${replica.length < 25 ? 'ant-pagination-disabled' : ''}`} onClick={PageUp} >
          <a className="ant-pagination-item-link">
            <span role="img" aria-label="right" className="anticon anticon-right">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
              </svg>
            </span>
          </a>
        </li>
      </ul>
    </>
  );
}