import { ActivityModel } from "./models";
import { epicApiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";



export const activityLog = createAsyncThunk(
  "/api/Activity/Get",
  async (
    _params: {
      retailerId?: string,
      pagenumber: number,
      logType?: string,
      limit?: number,
      searchText: string,
      fromDate?: string,
      toDate?: string
    },
    thunkAPI
  ): Promise<ActivityModel[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const queryParameters = [];
      if (_params.logType) {
        queryParameters.push(`logType=${_params.logType}`);
      }
      if (_params.limit) {
        queryParameters.push(`limit=${_params.limit}`);
      }
      if (_params.searchText) { 
        queryParameters.push(`searchText=${_params.searchText}`);
      }
      if (_params.fromDate) {
        queryParameters.push(`fromDate=${_params.fromDate}`);
      }
      if (_params.toDate) {
        queryParameters.push(`toDate=${_params.toDate}`);
      }

      const retailersResponse = await epicApiClient.get(
        `api/GetActivityLogs?pagenumber=${_params.pagenumber}&${queryParameters.join("&")}`,
        createAuthConfig()
      );
      const x: ActivityModel[] = retailersResponse.data;
      return x as ActivityModel[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting connections");
    }
  }
);