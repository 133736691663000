import { Service, ServiceWithoutId } from "./models";
import { epicApiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";
import { Subservice } from "../subservices/models";

interface MyData {
  "service": []
}
// Get Services
export const getServices = createAsyncThunk(
  "api/allservices",
  async (
    _params: { retailerId?: string },
    thunkAPI
  ): Promise<Service[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const servicesResponse = await epicApiClient.get<MyData>(
        `api/allservices`,
        createAuthConfig()
      );
      const x: Service[] = servicesResponse.data["service"]
      return x as Service[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error while getting Services list  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);
// Add Service
export const addService = createAsyncThunk(
  "api/addservice",
  async (
    params: ServiceWithoutId,
    thunkAPI
  ): Promise<Service | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const serviceResponse = await epicApiClient.post<{ message: string, service: Service }>(
        "api/addservice",
        params,
        createAuthConfig()
      );
      return serviceResponse.data.service;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error while adding service \ ${e?.response?.data?.error} ${e?.response?.data?.message} `);
    }
  }
);
// Edit Service
export const editService = createAsyncThunk(
  "/api/editservice",
  async (
    params: Service,
    thunkAPI
  ): Promise<Service | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const serviceResponse = await epicApiClient.post<{ message: string, service: Service }>(
        `/api/editservice`,
        params,
        createAuthConfig()
      );
      return serviceResponse.data.service;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error while editing Service ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);
// Delete Service
// export const deleteService = createAsyncThunk(
//   "/api/deleteservice",
//   async (
//     params: Partial<Service>,
//     thunkAPI
//   ): Promise<string | undefined | ReturnType<typeof thunkAPI.rejectWithValue>> => {
//     try {
//       const response = await epicApiClient.post<{ message: string, statusCode: number }>(
//         `api/deleteservice/${params.id}`,
//         {},
//         createAuthConfig()
//       );
//       if (response.data.statusCode == 200) {
//         return params.id;
//       }
//       else {
//         return thunkAPI.rejectWithValue(response.data.message);
//       }
//     } catch (e) {
//       console.log(e);
      
//       if (e.response && e.response.data && e.response.data.status === 400) {
//         return thunkAPI.rejectWithValue(
//           [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
//         );
//       }
//       return thunkAPI.rejectWithValue(`Error deleting Service  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
//     }
//   }
// );
export const deleteService = createAsyncThunk(
  "/api/deleteservice",
  async (
    params: Partial<Service>,
    thunkAPI
  ): Promise<string | undefined | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await epicApiClient.post<{ message: string, statusCode: number }>(
        `api/deleteservice/${params.id}`,
        {},
        createAuthConfig()
      );
      if (response.data.statusCode == 200) {
        return params.id;
      }
      else {
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (e) {
      console.log(e);

      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error deleting Service  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

// Get Subservice Of Service
export const getSubserviceOfService = createAsyncThunk(
  "/api/Connections/Get",
  async (
    _params: { serviceID?: string },
    thunkAPI
  ): Promise<Subservice[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const servicesResponse = await epicApiClient.get<{ message: string, Subservice: Subservice[] }>(
        `api/getsubservicedetail/${_params.serviceID}`,
        createAuthConfig()
      );
      return servicesResponse.data.Subservice;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error getting Subservices  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

export const getServiceByRetailerId = createAsyncThunk(
  "api/GetRetailerdetail",
  async (
    _params: { retailerId?: string },
    thunkAPI
  ): Promise<Service[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const servicesResponse = await epicApiClient.get<MyData>(
        `api/GetRetailerdetail/${_params.retailerId}`
      );
      const x: Service[] = servicesResponse.data["service"]
      return x as Service[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error while getting Services list  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);