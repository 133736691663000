import { authSlice, loginSlice } from "./auth/slice";
import { retailersSlice } from "./retailers/slice";
import { usersSlice } from "./users/slice";
import { circularGroupsSlice } from "./circular-groups/slice";
import { storesSlice } from "./stores/slice";
import { circularsSlice } from "./circulars/slice";
import { editPageSlice } from "./areas/slice";
import { categoriesSlice } from "./categories/slice";
import { currentRetailerSlice } from "./select-retailer/slice";
import { recipesSlice } from "./recipes/slice";
import { emailsSlice } from "./emails/slice";
import { connectionsSlice } from "./connections/slice";
import { servicesSlice } from "./services/slice";
import { propertiesSlice } from "./properties/slice";
import { subservicesSlice } from "./subservices/slice";
import { activitySlice } from "./activitylog/slice";

export const rootReducer = {
  auth: authSlice.reducer,
  retailers: retailersSlice.reducer,
  users: usersSlice.reducer,
  circularGroups: circularGroupsSlice.reducer,
  stores: storesSlice.reducer,
  circulars: circularsSlice.reducer,
  editPage: editPageSlice.reducer,
  login: loginSlice.reducer,
  categories: categoriesSlice.reducer,
  currentRetailer: currentRetailerSlice.reducer,
  recipes: recipesSlice.reducer,
  emails: emailsSlice.reducer,
  connections: connectionsSlice.reducer,
  services: servicesSlice.reducer,
  subservices: subservicesSlice.reducer,
  properties: propertiesSlice.reducer,
  activity:activitySlice.reducer,
};

export const Actions = {
  auth: authSlice.actions,
  users: usersSlice.actions,
  retailers: retailersSlice.actions,
  circularGroups: circularGroupsSlice.actions,
  stores: storesSlice.actions,
  circulars: circularsSlice.actions,
  editPage: editPageSlice.actions,
  categories: categoriesSlice.actions,
  login: loginSlice.actions,
  currentRetailer: currentRetailerSlice.actions,
  recipes: recipesSlice.actions,
  emails: emailsSlice.actions,
};
