import React from "react";
import styled from "styled-components";
import ActivityLogsTable from "./activityTable";

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function ActivityGroups() {

    return (
        <>
            <Container>
                <HeaderContainer>
                    <h1>Activity Logs</h1>
                </HeaderContainer>
                <ActivityLogsTable />
            </Container>
        </>
    );
}
