import { createSlice } from "@reduxjs/toolkit";
import { activityLog } from "./api";
import { ActivityModel } from "./models";

export type ConnectionState = {
  [x: string]: any;
  all: ActivityModel[];
  loading: boolean;
  error: string | null;
};

const initialState: ConnectionState = {
  all: [],
  loading: false,
  error: null,
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    /* get connections */
    builder.addCase(activityLog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activityLog.fulfilled, (state, action) => {
      state.all = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(activityLog.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});