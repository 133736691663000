import { Retailer, RetailerWithoutId } from "./models";
import { apiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";

export const getRetailers = createAsyncThunk(
  "/api/Retailers/Get",
  async (
    _params: { retailerId?: string },
    thunkAPI
  ): Promise<Retailer[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailersResponse = await apiClient.get<Retailer[]>(
        `api/Retailers?retailerId=${
          _params.retailerId ? _params.retailerId : ""
        }`,
        createAuthConfig()
      );
      return retailersResponse.data as Retailer[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting retailers");
    }
  }
);

export const addRetailer = createAsyncThunk(
  "/api/Retailers/Add",
  async (
    params: RetailerWithoutId,
    thunkAPI
  ): Promise<Retailer | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await apiClient.post<Retailer>(
        "api/Retailers",
        params,
        createAuthConfig()
      );
      return retailerResponse.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error adding retailer");
    }
  }
);

export const editRetailer = createAsyncThunk(
  "/api/Retailers/Edit",
  async (
    params: Retailer,
    thunkAPI
  ): Promise<Retailer | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await apiClient.post<Retailer>(
        `api/Retailers/${params.id}`,
        params,
        createAuthConfig()
      );
      return retailerResponse.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error editing retailer");
    }
  }
);

export const deleteRetailer = createAsyncThunk(
  "/api/Retailers/Delete",
  async (
    params: Partial<Retailer>,
    thunkAPI
  ): Promise<Retailer | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await apiClient.post<Retailer>(
        `api/Retailers/DeleteRetailer/${params.id}`,
        {},
        createAuthConfig()
      );
      return response.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting retailer");
    }
  }
);
